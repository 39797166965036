import React from 'react';
import { LayoutSectionElem } from '../../common/layout-section';
import { HeaderContainer } from '../../epic/header';

import { LayoutAppElem } from '../../common/layout-app';
import { LayoutElem } from '../../common/layout';
import { PageElem } from '../../common/page';
import { AccountSettingsChangeEmailContainer } from '../../epic/account-settings-change-email';
import { AccountSettingsChangePasswordContainer } from '../../epic/account-settings-change-password';
import icon from '../../asset/svg/header/settings.svg';
import iconLight from '../../asset/svg/header/settings-light.svg';
import { THEME_MODULE_NAME } from '../../data/theme';
import { useSelector } from '../../lib/store';
import { THEME_ENUM } from '../../data/theme/constant';

export const Page: React.FC = () => {
  const { theme } = useSelector((s: any) => ({
    theme: s[THEME_MODULE_NAME],
  }));
  return (
    <PageElem>
      <LayoutAppElem>
        <LayoutElem>
          <HeaderContainer
            icon={theme.type === THEME_ENUM.LIGHT ? iconLight : icon}
            title="ACCOUNT.SETTINGS.TITLE"
          />
          <LayoutSectionElem spacing={6}>
            <AccountSettingsChangeEmailContainer />
            <AccountSettingsChangePasswordContainer />
          </LayoutSectionElem>
        </LayoutElem>
      </LayoutAppElem>
    </PageElem>
  );
};
