import { i18n } from '../../lib/lang';
import { COLOR_ENUM } from '../../theme/color';
import { FILE_ITEM_DATA_RAW_INTER } from '../file/constant';
import { USER_DATA_INTER } from '../user/constant';
import { CURRENCY_TYPE } from '../valute/constant';

export enum EXPENDITURE_CATEGORY {
  PAYMENT_REQUEST = 'PAYMENT_REQUEST',
}

export const EXPENDITURE_CATEGORY_OPTION_LIST = [
  {
    value: EXPENDITURE_CATEGORY.PAYMENT_REQUEST,
    label: `${i18n.t('EXPENDITURE.CATEGORY.PAYMENT_REQUEST')}`,
  },
];

export enum EXPENDITURE_BILL {
  CARD = 'CARD',
  CRYPTO = 'CRYPTO',
  CASH = 'CASH',
  NON_CASH = 'NON_CASH',
}

export const EXPENDITURE_BILL_OPTION_LIST = [
  {
    value: EXPENDITURE_BILL.CARD,
    label: `${i18n.t('EXPENDITURE.BILL.CARD')}`,
  },
  {
    value: EXPENDITURE_BILL.CRYPTO,
    label: `${i18n.t('EXPENDITURE.BILL.CRYPTO')}`,
  },
  {
    value: EXPENDITURE_BILL.CASH,
    label: `${i18n.t('EXPENDITURE.BILL.CASH')}`,
  },
  {
    value: EXPENDITURE_BILL.NON_CASH,
    label: `${i18n.t('EXPENDITURE.BILL.NON_CASH')}`,
  },
];

export enum EXPENDITURE_DEPARTMENT {
  MANAGEMENT = 'MANAGEMENT',
  TRAFFIC = 'TRAFFIC',
  FUNNEL = 'FUNNEL',
  TECHNICAL = 'TECHNICAL',
  ANALYTICS = 'ANALYTICS',
  SALES = 'SALES',
  PRODUCT = 'PRODUCT',
}

export const EXPENDITURE_DEPARTMENT_OPTION_LIST = [
  {
    value: EXPENDITURE_DEPARTMENT.MANAGEMENT,
    label: `${i18n.t('EXPENDITURE.DEPARTMENT.MANAGEMENT')}`,
  },
  {
    value: EXPENDITURE_DEPARTMENT.TRAFFIC,
    label: `${i18n.t('EXPENDITURE.DEPARTMENT.TRAFFIC')}`,
  },
  {
    value: EXPENDITURE_DEPARTMENT.FUNNEL,
    label: `${i18n.t('EXPENDITURE.DEPARTMENT.FUNNEL')}`,
  },
  {
    value: EXPENDITURE_DEPARTMENT.TECHNICAL,
    label: `${i18n.t('EXPENDITURE.DEPARTMENT.TECHNICAL')}`,
  },
  {
    value: EXPENDITURE_DEPARTMENT.ANALYTICS,
    label: `${i18n.t('EXPENDITURE.DEPARTMENT.ANALYTICS')}`,
  },
  {
    value: EXPENDITURE_DEPARTMENT.SALES,
    label: `${i18n.t('EXPENDITURE.DEPARTMENT.SALES')}`,
  },
  {
    value: EXPENDITURE_DEPARTMENT.PRODUCT,
    label: `${i18n.t('EXPENDITURE.DEPARTMENT.PRODUCT')}`,
  },
];

export const EXPENDITURE_VALUTE_OPTION_LIST = [
  {
    value: CURRENCY_TYPE.USD,
    label: `${i18n.t('EXPENDITURE.VALUTE.USD')}`,
  },
  {
    value: CURRENCY_TYPE.EUR,
    label: `${i18n.t('EXPENDITURE.VALUTE.EUR')}`,
  },
  {
    value: CURRENCY_TYPE.UAH,
    label: `${i18n.t('EXPENDITURE.VALUTE.UAH')}`,
  },
  {
    value: CURRENCY_TYPE.PLN,
    label: `${i18n.t('EXPENDITURE.VALUTE.PLN')}`,
  },
];

export enum EXPENDITURE_STATUS {
  IN_PROGRESS = 'IN_PROGRESS',
  CANCEL = 'CANCEL',
  PAYEED = 'PAYEED',
}

export const EXPENDITURE_BILL_COLOR = {
  [EXPENDITURE_BILL.CARD]: COLOR_ENUM.SUCCESS,
  [EXPENDITURE_BILL.CASH]: COLOR_ENUM.ERROR,
  [EXPENDITURE_BILL.CRYPTO]: COLOR_ENUM.YELLOW,
  [EXPENDITURE_BILL.NON_CASH]: COLOR_ENUM.BLUE,
};

export const EXPENDITURE_STATUS_COLOR = {
  [EXPENDITURE_STATUS.IN_PROGRESS]: COLOR_ENUM.BLUE,
  [EXPENDITURE_STATUS.CANCEL]: COLOR_ENUM.ERROR,
  [EXPENDITURE_STATUS.PAYEED]: COLOR_ENUM.SUCCESS,
};

export const EXPENDITURE_STATUS_OPTION_LIST = [
  {
    value: EXPENDITURE_STATUS.IN_PROGRESS,
    label: `${i18n.t('EXPENDITURE.STATUS.IN_PROGRESS')}`,
  },
  {
    value: EXPENDITURE_STATUS.CANCEL,
    label: `${i18n.t('EXPENDITURE.STATUS.CANCEL')}`,
  },
  {
    value: EXPENDITURE_STATUS.PAYEED,
    label: `${i18n.t('EXPENDITURE.STATUS.PAYEED')}`,
  },
];

export interface EXPENDITURE_ITEM_DATA_RAW_INTER {
  id: string;

  description: string;

  requisites: string;

  createDate: string;

  deadLine: string;

  valute: CURRENCY_TYPE;

  amount: string;

  category: EXPENDITURE_CATEGORY;

  bill: EXPENDITURE_BILL;

  departmant: EXPENDITURE_DEPARTMENT;

  status: EXPENDITURE_STATUS;

  responsible: USER_DATA_INTER;

  comment: string;

  confirmFile: FILE_ITEM_DATA_RAW_INTER;

  commentFile: FILE_ITEM_DATA_RAW_INTER;
}

export interface EXPENDITURE_ITEM_LIST_DATA_RAW_INTER {
  list: EXPENDITURE_ITEM_DATA_RAW_INTER[];
}

export interface EXPENDITURE_ITEM_DATA_INTER
  extends EXPENDITURE_ITEM_DATA_RAW_INTER {}

export interface EXPENDITURE_ITEM_LIST_DATA_INTER {
  list: EXPENDITURE_ITEM_DATA_INTER[];
  isEmpty: boolean;
}
