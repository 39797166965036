import React from 'react';
import { LayoutAppElem } from '../../common/layout-app';
import { LayoutElem } from '../../common/layout';
import { PageElem } from '../../common/page';
import { ExpenditureListContainer } from '../../epic/expenditure-list';
import { TextElem } from '../../common/text';
import styled from 'styled-components';
import { AccountSettingsLogoutContainer } from '../../epic/account-settings-logout';
import { FlexElem } from '../../common/flex';

export const Page: React.FC = () => {
  return (
    <PageElem>
      <LayoutAppElem oneColumn>
        <LayoutCustom oneColumn size="default">
          <FlexElem style={{ justifyContent: 'space-between' }}>
            <TextElem
              tid="EXPENDITURE.HEAD.TITLE"
              type="semi-bold"
              size="heading"
              // style={{ textAlign: 'center' }}
            />
            <AccountSettingsLogoutContainer />
          </FlexElem>

          <ExpenditureListContainer />
        </LayoutCustom>
      </LayoutAppElem>
    </PageElem>
  );
};

const LayoutCustom = styled(LayoutElem)`
  max-width: 1300px;
`;
