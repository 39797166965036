export enum CURRENCY_TYPE {
  USD = 'USD',
  EUR = 'EUR',
  UAH = 'UAH',
  PLN = 'PLN',
}

export const CURRENCY_TYPE_SYMBOL = {
  [CURRENCY_TYPE.USD]: '$',
  [CURRENCY_TYPE.EUR]: '€',
  [CURRENCY_TYPE.UAH]: '₴',
  [CURRENCY_TYPE.PLN]: 'zł',
};
