import React, { ReactNode, useEffect, useState } from 'react';

import { FilePicker, PickedFile } from '@capawesome/capacitor-file-picker';
import { useMutation, useQueryClient } from 'react-query';

import { FILE_ITEM_DATA_RAW_INTER } from '../../data/file/constant';
import {
  allowedFileTypes,
  allowedImageTypes,
  allowedVideoTypes,
  API,
} from './constant';
import { HttpRequest } from '../../lib/http';
import { useDropzone } from 'react-dropzone';
import { ImageComponent } from './view/image';
import { FileComponent } from './view/file';
import { VideoComponent } from './view/video';
import { i18n } from '../../lib/lang';

export const Container: React.FC<{
  children?: ReactNode;
  onSuccess?: Function;
  onReset?: Function;
  errorMessage?: string;
  name?: string;
  defaultValue?: string;
  type?: 'file' | 'image' | 'video';
  description?: boolean;
  publicUrl?: boolean;
  resetAfterUpload?: boolean;
}> = ({
  defaultValue,
  children,
  name,
  onSuccess,
  onReset,
  errorMessage,
  type = 'file',
  description = true,
  publicUrl = false,
  resetAfterUpload = false,
}) => {
  const query = useQueryClient();

  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState('');
  const [progress, setProgress] = useState(0);
  const [value, setValue] = useState(defaultValue);
  const [modalPreviewVisible, setModalPreviewVisible] = useState(false);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const openModalPreview = (e: any) => {
    e.stopPropagation();
    setModalPreviewVisible(true);
  };

  const closeModalPreview = (e: any) => {
    e.stopPropagation();

    setModalPreviewVisible(false);
  };

  const action = async (formData: FormData) => {
    const file = formData.get('file');

    if (file instanceof File) {
      const fileType = file.type;

      if (type === 'image' && !allowedImageTypes.includes(fileType)) {
        setError(i18n.t('FILE.UPLOAD.ERROR.TYPE.IMAGE'));
      } else if (type === 'video' && !allowedVideoTypes.includes(fileType)) {
        setError(i18n.t('FILE.UPLOAD.ERROR.TYPE.VIDEO'));
      } else if (type === 'file' && !allowedFileTypes.includes(fileType)) {
        setError(i18n.t('FILE.UPLOAD.ERROR.TYPE.FILE'));
      } else {
        if (!!error) {
          setError('');
        }
        const response = await HttpRequest({
          method: API.MAIN.TYPE,
          url: publicUrl ? API.MAIN.PUBLIC_URL : API.MAIN.URL,
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: (progressEvent) => {
            const progress = (progressEvent.loaded / progressEvent.total) * 100;
            setProgress(progress);
          },
          onDownloadProgress: (progressEvent) => {
            const progress = (progressEvent.loaded / progressEvent.total) * 100;
            setProgress(progress);
          },
        });
        setIsSuccess(true);
        setProgress(0);
        return response;
      }
    }
  };

  const uploadFile = useMutation((data: any) => action(data), {});

  const getFile = async () => {
    try {
      const result = await FilePicker.pickFiles({ readData: true });
      return result.files[0];
    } catch (err) {
      console.log(err);
    }
  };

  const handleClick = async () => {
    if (
      !(!!uploadFile.isSuccess || (!!defaultValue && !uploadFile.isLoading))
    ) {
      const file = await getFile();
      if (file) {
        appendFileToFormData(file);
      }
    }
  };

  const handleReselect = async (e: any) => {
    e.stopPropagation();
    const file = await getFile();
    if (file) {
      appendFileToFormData(file);
    }
    if (onReset) {
      onReset(name);
    }
  };

  const handleReset = (e: any) => {
    e.stopPropagation();

    uploadFile.reset();
    if (value) {
      setValue(undefined);
    }
    if (onReset) {
      onReset(name);
    }
    if (onSuccess) {
      onSuccess(null, name);
    }
  };

  const appendFileToFormData = async (file: PickedFile) => {
    try {
      const formData = new FormData();
      if (file.blob) {
        const rawFile = new File([file.blob as BlobPart], file.name, {
          type: file.mimeType,
        });

        formData.append('file', rawFile, file.name);
        uploadFile.mutate(formData);
      }
      if (file.data && !file.blob) {
        const base64Response = await fetch(
          `data:${file.mimeType};base64,${file.data}`,
        );

        const blob = await base64Response.blob();

        const rawFile = new File([blob as BlobPart], file.name, {
          type: file.mimeType,
        });

        formData.append('file', rawFile, file.name);
        uploadFile.mutate(formData);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getData = (): FILE_ITEM_DATA_RAW_INTER | undefined => {
    const data = uploadFile.data as unknown as FILE_ITEM_DATA_RAW_INTER;

    if (data) {
      return data;
    }
  };
  const data = getData();

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      const formData = new FormData();
      formData.append('file', acceptedFiles[0]);
      uploadFile.mutate(formData);
    },
  });

  {
    switch (type) {
      case 'image':
        return (
          <ImageComponent
            data={data}
            getInputProps={getInputProps}
            getRootProps={getRootProps}
            uploadFile={uploadFile}
            defaultValue={value}
            handleClick={handleClick}
            //@ts-ignore
            error={error || uploadFile?.error?.message}
            progress={progress}
            handleReselect={handleReselect}
            handleReset={handleReset}
            modalPreviewVisible={modalPreviewVisible}
            openModalPreview={openModalPreview}
            closeModalPreview={closeModalPreview}
            description={description}
          />
        );
      case 'video':
        return (
          <VideoComponent
            data={data}
            getInputProps={getInputProps}
            getRootProps={getRootProps}
            uploadFile={uploadFile}
            defaultValue={value}
            handleClick={handleClick}
            //@ts-ignore
            error={error || uploadFile?.error?.message}
            progress={progress}
            handleReselect={handleReselect}
            handleReset={handleReset}
            modalPreviewVisible={modalPreviewVisible}
            openModalPreview={openModalPreview}
            closeModalPreview={closeModalPreview}
            description={description}
          />
        );

      default:
        return (
          <FileComponent
            data={data}
            getInputProps={getInputProps}
            getRootProps={getRootProps}
            uploadFile={uploadFile}
            defaultValue={value}
            handleClick={handleClick}
            //@ts-ignore
            error={error || uploadFile?.error?.message}
            progress={progress}
            handleReselect={handleReselect}
            handleReset={handleReset}
          />
        );
    }
  }
};
